<template>
  <ion-page>
    <app-header back="home" title="운송사 월별 운반현황" />
    <ion-content :fullscreen="true">
      <template v-if="isConnected">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size-md="1" size-xs="2">
              <span> 년월</span>
            </ion-col>
            <ion-col size-md="2" size-xs="10">
              <ion-input class="calendar" v-model="frMonth" id="frMonth" readonly @click ="modalChange">
                <ion-icon :icon="calendar"></ion-icon>
              </ion-input>
              <ion-modal trigger="frMonth" id="www" @willDismiss="onWillDismiss">
                <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM"
                  v-model="frMonth" presentation="month-year"
                  :show-default-buttons="true"></ion-datetime>
              </ion-modal>
            </ion-col>
            <ion-col size-md="5" size-xs="4">
              <ion-input id="ddd" class="ion-text-center" readonly placeholder="착지 검색"
                style="text-align: right; align-items: center; margin: auto;" @click="openModal" @ionInput="cgname($event)"></ion-input>
            </ion-col>
            <ion-col size-md="2" size-xs="4">
              <ion-button expand="block" class="excel" @click="exportToExcel" color="success">엑셀변환</ion-button>
            </ion-col>
            <ion-col size-md="2" size-xs="4">
              <ion-button expand="block" @click="onReConnect" color="medium">조회</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <vue-good-table 
          :columns="columns" 
          :rows="crdms" 
          :row-style-class="rowStyleFunction"
          :sort-options="{
            enabled: false,
          }"
          max-height="74vh"> 
       </vue-good-table>
      </template>
      <template v-else>
        <network-error v-on:@onReConnect="onReConnect" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonInput,
  IonModal,
  IonCol,
  IonRow,
  IonDatetime,
  onIonViewWillEnter,
  IonButton,
  modalController,
  IonGrid,
  IonIcon,
  onIonViewWillLeave
} from '@ionic/vue';

import { defineComponent, reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';
import { UseCrdm } from '@/api/services/crdm';
import { setDateFormat, getDayOfWeek  } from '@/utils/dateFormatter';
import { calendar } from 'ionicons/icons';
import DplaceListModal from '@/views/modal/DplaceListModal.vue';
import * as XLSX from 'xlsx';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonPage,
    IonInput,
    IonModal,
    IonContent,
    IonCol,
    IonRow,
    IonIcon,
    IonDatetime,
    VueGoodTable,
    IonButton,
    IonGrid,
  },
  setup() {
    const frMonth = ref(setDateFormat(Date.now(), 'YYYY-MM')); 
    const { isConnected, fetchDatas, year, month, crdms } = UseCrdm();
    const salenm = reactive({ title: null, });
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '운송사월별운반현황');
    });

    const form = reactive({
      modalState: false
    });
    async function onWillDismiss() {
      form.modalState = false;
    }
    async function modalChange() {
      form.modalState = true;
    }
    function customd01(row) {
      if (row.sale_qunt) return row.sale_qunt.toLocaleString('ko-KR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    function customdc(row) {
      if (row.sale_qunt ) return 'currency';
    }

    const columns = reactive([
      {
        label: '착지',
        field: 'dplace_name',
        width: '120px',
      },
      {
        label: '출하일자',
        field: 'sa_date_nm',
        width: '85px',
      },
      {
        label: '인도조건',
        field: 'd_name',
        width: '85px',
      },
      {
        label: '품목',
        field: 'itnm',
        width: '120px',
      },
      {
        label: '출하량',
        field:  customd01,
        width: '100px',
        tdClass: customdc,
      },
    ]);

    const exportToExcel = () => {
      const data =  Object.values(crdms.value).map(row => [row.flag_name, row.dplace_name, row.sa_date_nm, row.sicdname, row.d_name, row.itnm, row.sale_qunt,]);
      data.unshift(columns.map(col => col.label));
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "운송사월별운반현황.xlsx");
    }

    onIonViewWillEnter(async () => {
      // const frdutiesPromise = fetchDatas(frMonth.value.substring(0, 10).replaceAll('-', ''), frMonth.value.substring(0, 10).replaceAll('-', ''), salenm.title, '');
      // await frdutiesPromise;
    });

    async function onReConnect() {
      const frdutiesPromise = fetchDatas(frMonth.value.substring(0, 7).replaceAll('-', ''), '', salenm.title == null ? '%25' : salenm.title);
      await frdutiesPromise;
    }

    function cgname(e) {
      if (e == null) {
        document.querySelector('.ion-text-center').value = null;
      } else {
        document.querySelector('.ion-text-center').value = "[" + e + " ]";
      }
    }

    async function openModal() {
      const distinctDplaceCodes = Array.from(new Set(crdms.value.map(crdms => `${crdms.dplace_code} / ${crdms.dplace_name}`)
      .filter((dplaceCode) => !dplaceCode.includes("소계")).filter((dplaceCode) => !dplaceCode.includes("합계")).sort()
      ));

      form.modalState = true;
      const modal = 
        await modalController.create({
          component: DplaceListModal,
          componentProps:{
            seachingFlag: 'All',
            distinctDplaceCodes: distinctDplaceCodes
          }
        });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        cgname(data.substring(8));
        salenm.title = data.substring(0, 7);
      } else {
        salenm.title = data;
        cgname(salenm.title);
      }
      form.modalState = false;
    }

    function rowStyleFunction(row) {
      if(row.dplace_name){
        if (row.dplace_name.substring(0, 2) === '소계') {
          return 'row-subtotal-color';
        } else if (row.dplace_name.substring(0, 2) === '합계'){
          return 'row-total-color';
        } 
      }
    } 
    onIonViewWillLeave(async () => {
      if (form.modalState == true) {
        await modalController.dismiss()
      }
    });

    return {
      isConnected,
      year,
      month,
      frMonth,
      calendar,
      columns,
      crdms,
      getDayOfWeek,
      onReConnect,
      exportToExcel,
      openModal,
      rowStyleFunction,
      form,
      modalChange,
      onWillDismiss
    };
  }
});

</script>
<style scoped>
ion-content {
  text-align: center;
}
ion-col {
  padding: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  color: black;
  width: 100%;
}
ion-col .calendar {
    padding: 0;
}
ion-grid {
    padding: 0;
}
.title {
  display: block;
  text-align: center;
  font-size: 1.2em;
  padding: 0.5em;
}

section {
  display: flex;
  align-items: center ;
  justify-content: space-between;
  padding: 0 1rem;
  max-width: 300px;
  width: 100%;
}

ion-item {
  text-align: center;
}

ion-datetime {
  width: 100%;
} 

</style>